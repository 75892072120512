import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { get, first } from 'lodash';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import ProductPage from '../components/products/ProductPage';
import ProductsHeader from '../components/ProductsHeader';
import withRedirectServerData from '../components/withRedirectServerData';
import fetchProductsNoSSR from '../../integration/fetchProductsNoSSR';
import SEOHelmet from '../components/SEOHelmet';

const ProductVendors = (props) => {
  /* const { serverData = {} } = props;
  const { data } = serverData;
  const title = get(first(data?.products), 'vendor.name'); */

  const [loading, setLoading] = useState(true);
  const [listResult, setListResult] = useState({});
  const [title, setTitle] = useState('');

  useEffect(async () => {
    setLoading(true);
    const p = await fetchProductsNoSSR('vendorSlug')({
      paramInfo: {},
      location: get(props, 'location', {}),
    });
    setTitle(get(first(p?.data?.products), 'vendor.name'));
    setListResult(p);
    setLoading(false);
  }, [props]);

  return (
    <ProductsHeader title={title}>
      <SEOHelmet
        title={title}
        description={`Browse our full ${title} product catalogue. Contact sales for pricing and inventory.`}
      />
      {loading ? (
        <Box
          sx={{
            display: 'flex',
            height: '50vh',
            justifyContent: 'center',
            padding: '2rem',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <ProductPage {...listResult?.data} />
      )}
    </ProductsHeader>
  );
};

ProductVendors.propTypes = {
  serverData: PropTypes.shape({
    data: PropTypes.shape({
      products: PropTypes.arrayOf(PropTypes.object),
    }),
  }).isRequired,
};

export default ProductVendors;

/* export default withRedirectServerData(ProductVendors, {
  destination: '/vendors',
});

export async function getServerData(req) {
  return fetchProducts('vendorSlug')(req);
} */
